<template>
  <div class="content">
    <a-col :span="6">
      <a-form-item class="travel-input-outer">
        <label :class="form.getFieldValue(`bank_${bankId}`) ? 'filled' : ''"
          >Banco</label
        >
        <a-select
          class="travel-input"
          show-search
          placeholder="Banco"
          v-decorator="[
            `bank_name_${bankId}`,
            {
              initialValue: bankData.name,
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of banks"
            :key="index"
            :value="item.nome"
          >
            {{ item.numero }} - {{ item.nome }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="4">
      <a-form-item>
        <label
          :class="form.getFieldValue(`bank_${bankId}_agency`) ? 'filled' : ''"
        >
          Agência
        </label>
        <a-input
          class="travel-input"
          placeholder="Agência"
          v-decorator="[
            `bank_${bankId}_agency`,
            {
              initialValue: bankData.agency,
              rules: [{ required: true, message: 'Obrigatório' }],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="5">
      <a-form-item>
        <label
          :class="form.getFieldValue(`bank_${bankId}_account`) ? 'filled' : ''"
        >
          Conta
        </label>
        <a-input
          class="travel-input"
          placeholder="Conta"
          v-decorator="[
            `bank_${bankId}_account`,
            {
              initialValue: bankData.account,
              rules: [{ required: true, message: 'Obrigatório' }],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="3">
      <a-form-item>
        <label
          :class="
            form.getFieldValue(`bank_${bankId}_account_digit`) ? 'filled' : ''
          "
        >
          Dígito
        </label>
        <a-input
          class="travel-input"
          placeholder="Dígito"
          v-decorator="[
            `bank_${bankId}_account_digit`,
            {
              initialValue: bankData.digit,
              rules: [{ required: true, message: 'Obrigatório' }],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="6">
      <a-form-item>
        <label
          :class="form.getFieldValue(`bank_${bankId}_pix`) ? 'filled' : ''"
        >
          Pix
        </label>
        <a-input
          class="travel-input"
          placeholder="Pix (opcional)"
          v-decorator="[
            `bank_${bankId}_pix`,
            {
              initialValue: bankData.pix,
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </a-col>
  </div>
</template>

<script>
import { format } from "date-fns";
import subYears from "date-fns/subYears";
import states from "@/json/states";
import banks from "../../json/banks.json";

export default {
    name: "BankData",
  props: ["form", "bankId", "bankData"],
  data() {
    return {
      states,
      banks,
    };
  },
  methods: {
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: this.form.getFieldValue("birthday")
            ? this.form.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
  },
};
</script>

<style scoped></style>
